import React from "react"
import { Link } from "gatsby"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

const Header = () => {
  const data = useStaticQuery(graphql`
    query Images {
      image: file(relativePath: { eq: "TheLogoMan.png" }) {
        childImageSharp {
          fixed(width: 70) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <header className="Header">
      <Link to="/">
        <div className="logoTitle">
          <Img
            width="55"
            height="60"
            id="logo"
            fixed={data.image.childImageSharp.fixed}
            alt="The band academy Logo"
          />
        </div>
      </Link>
      <div className="navTitle">
        <Link to="/">
          <p>The Band Academy</p>
        </Link>
        <nav id="navbar" className="NavDesktop">
          <ul>
            <Link
              to="/"
              activeStyle={{
                color: "#f556b1",
              }}
            >
              Home
            </Link>

            <Link
              to="/academy"
              activeStyle={{
                color: "#f556b1",
              }}
            >
              Academy
            </Link>

            <Link
              to="/about"
              activeStyle={{
                color: "#f556b1",
              }}
            >
              About
            </Link>

            <Link
              to="/prices"
              activeStyle={{
                color: "#f556b1",
              }}
            >
              Prices
            </Link>

            <Link
              to="/contact"
              activeStyle={{
                color: "#f556b1",
              }}
            >
              Contact
            </Link>

            <Link
              to="/blog"
              activeStyle={{
                color: "#f556b1",
              }}
            >
              Blog
            </Link>
          </ul>
        </nav>
      </div>
    </header>
  )
}

export default Header
