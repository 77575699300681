import React from "react"
import Header from "./Header"
import Footer from "./Footer"
import Ui from "./Ui"

const Layout = props => {
  return (
    <div className="container">
      <Header />
      <div className="content">{props.children}</div>
      <Ui />
      <Footer />
    </div>
  )
}

export default Layout
