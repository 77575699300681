import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Link } from "gatsby"
import Img from "gatsby-image"

const Footer = () => {
  const data = useStaticQuery(graphql`
    query ImagesTwo {
      image: file(relativePath: { eq: "TheLogoMan.png" }) {
        childImageSharp {
          fixed(width: 70) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  return (
    <footer className="footer">
      <div className="footTop">
        <Link to="/">
          <div className="logTitle">
            <Img
              id="logo"
              width="55"
              height="60"
              fixed={data.image.childImageSharp.fixed}
              alt="The band academy Logo"
            />
          </div>
        </Link>
        <Link to="/">
          <h2>The Band Academy</h2>
          <p>07415 095025</p>
          <p>&copy; {new Date().getFullYear()} </p>
        </Link>
        <a href="https://www.netfrontier.co.uk/" target="_blank" rel="noopener">
          Built By Net Frontier
        </a>
      </div>
    </footer>
  )
}

export default Footer
