import React from "react"
import Phone from "../assets/001-call.svg"
import Price from "../assets/pound.svg"
import Place from "../assets/003-placeholder.svg"
import { Link } from "gatsby"

const Ui = () => {
  return (
    <nav className="uiNav">
      <Link to="/prices">
        <img
          width="25"
          height="25"
          src={Price}
          alt="band academy music tuition price icon"
        />
      </Link>

      <Link className="middleIcon" to="/contact">
        <img
          width="25"
          height="25"
          src={Place}
          alt="band academy music tuition location icon"
        />
      </Link>

      <a href="tel:07415-095-025">
        <img
          width="25"
          height="25"
          src={Phone}
          alt="band academy music tuition phone icon"
        />
      </a>
    </nav>
  )
}

export default Ui
